import { getUserCountry, isCountryInRegion } from '../theme-scripts.js';

class AnnouncementBar extends HTMLElement {
  constructor() {
    super();
    this.initializeMessages();
  }

  async initializeMessages() {
    try {
      const userCountry = await getUserCountry();
      if (!userCountry) return;

      this.filterMessagesByRegion(userCountry);
      this.initAnimation();
    } catch (error) {
      console.error('Error initializing announcement bar:', error);
    }
  }

  filterMessagesByRegion(userCountry) {
    this.querySelectorAll('.text-animation-slider__item').forEach((element) => {
      const region = element.dataset.countries;
      const shouldShow = region === 'global' || this.isMessageValidForRegion(region, userCountry);

      if (!shouldShow) {
        element.remove();
      }
    });
  }

  isMessageValidForRegion(region, userCountry) {
    const regions = window.storeSelectorConfig?.regions;
    if (!regions) return false;

    switch (region) {
      case 'global':
        return true;
      case 'unsupported':
        return !Object.values(regions).some((r) => r.countryCodes.includes(userCountry));
      default:
        return isCountryInRegion(userCountry, region);
    }
  }

  initAnimation() {
    this.animateContainer = this.querySelector('.text-animation-slider__container');
    if (!this.animateContainer) return;

    this.animateSlider = this.querySelector('.text-animation-slider__slider');
    this.animatedItems = this.animateSlider.querySelectorAll('.text-animation-slider__item');

    if (!this.animatedItems.length) return;

    this.animatedItems[0].classList.add('active', 'animated');

    this.animateTextTimeout = null;
    this.animateTextTimeoutResize = null;
    this.firstLoad = true;
    this.animateDuration = parseInt(this.animateSlider.dataset.delay, 10);

    if (this.animatedItems.length > 1) {
      this.animate();
    }
  }

  animate() {
    this.animateTextTimeout = setTimeout(() => {
      const activeElement = this.animateSlider.querySelector('.active');
      if (!activeElement) return;

      const classes = {
        noAnimation: 'animated',
        animateIn: 'animate-in',
        animateOut: 'animate-out',
        active: 'active',
      };

      const nextElement = activeElement.nextElementSibling || this.animateSlider.firstElementChild;

      activeElement.classList.add(classes.animateOut);
      activeElement.classList.remove(classes.active, classes.noAnimation, classes.animateIn);

      nextElement.classList.add(classes.active, classes.animateIn);

      this.firstLoad = false;
      this.animate();
    }, this.animateDuration);
  }

  disconnectedCallback() {
    if (this.animateTextTimeout) {
      clearTimeout(this.animateTextTimeout);
    }
    if (this.animateTextTimeoutResize) {
      clearTimeout(this.animateTextTimeoutResize);
    }
  }
}

customElements.define('announcement-bar', AnnouncementBar);
